import { Button, Card, CardActions, CardContent } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { uniq } from 'lodash';
import pluralize from 'pluralize';
import React, { useCallback, useMemo, useState } from 'react';
import { AlertBox } from '../../../../components/AlertBox';
import {
  AnalyticsColumnDefinitions,
  useAnalyticsTable
} from '../../../../components/analytics_v2/Table';
import { useTimeframe } from '../../../../components/analytics_v2/Timeframe';
import { RowsRenderer } from '../../../../components/GroupableList';
import { Loader } from '../../../../components/Loader';
import { SelectionBox } from '../../../../components/SelectionBox';
import {
  AnalyticsQuery,
  AnalyticsResponseRowWithComparison
} from '../../../../domainTypes/analytics_v2';
import {
  CampaignOffsiteLink,
  CampaignOnsiteLink,
  IManualCampaign,
  isOffsiteLink,
  isOnsiteLink
} from '../../../../domainTypes/campaigns';
import { styled } from '../../../../emotion';
import { usePromise } from '../../../../hooks/usePromise';
import { Centered } from '../../../../layout/Centered';
import { FlexContainer } from '../../../../layout/Flex';
import { useNumberQueryParam } from '../../../../routes';
import { Metric } from '../../../../services/analyticsV2/metrics';
import {
  pub_queryAnalyticsV2,
  useAnalyticsQueryV2
} from '../../../../services/analyticsV2/query';
import { toChannelDoc } from '../../../../services/channels';
import { ChannelIdLabel } from '../../../../services/channels/ChannelLabel';
import { useCurrentUser } from '../../../../services/currentUser';
import { store } from '../../../../services/db';
import { removeTrailingSlash } from '../../../../services/url';
import { FS } from '../../../../versions';
import { ProductLinkCellLazyMinimal } from '../../../Links/pages/Overview/components/ProductLinkCell';
import { Header } from './form-components';
import { CampaignLinks } from './form-types';

interface CampaignLinksFormProps {
  campaign: IManualCampaign;
  proceed: (links: CampaignLinks, campaign: IManualCampaign) => void;
  back: () => void;
}

const onsiteLinksColumns = ['link', 'pages', 'selector'] as const;
type OnsiteCustomColumns = typeof onsiteLinksColumns[number];
type OnsiteColumn = OnsiteCustomColumns | Metric;

const LINK_TABLE_HEIGHT = 370;

const onsiteLinkTableColumns: OnsiteColumn[] = [
  'selector',
  'link',
  'pages',
  'commission_sum_net'
];

const LinkTableWrapper = styled('div')`
  height: ${LINK_TABLE_HEIGHT}px;
  overflow-y: auto;
  border: 1px solid ${(p) => p.theme.palette.divider};
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  margin: ${(p) => p.theme.spacing(4)}px 0;
`;

const getOnsiteColumnDefinitions = ({
  linkIds,
  toggleLink,
  toggleAll,
  isAllSelected
}: {
  linkIds: Set<string>;
  toggleLink: (link: CampaignOnsiteLink) => void;
  isAllSelected: boolean;
  toggleAll: () => void;
}): AnalyticsColumnDefinitions<OnsiteCustomColumns> => {
  return {
    selector: {
      column: {
        key: 'selector',
        head: () => (
          <Tooltip title={`Select all links`} placement="top">
            <SelectionBox value={isAllSelected} onChange={toggleAll} />
          </Tooltip>
        ),
        cell: (p) => (
          <SelectionBox
            value={linkIds.has(p.group.link_id)}
            onChange={() => toggleLink(toOnsiteLink(p))}
          />
        ),
        align: 'center',
        sortable: false,
        width: 50,
        flexGrow: 0
      },
      sorter: {
        key: 'selector',
        items: {
          sort: (p) => p.group.link_id,
          dir: 'asc'
        }
      }
    },
    link: {
      column: {
        key: 'link',
        head: () => 'Link name or deep link',
        cell: (row, o) => {
          return (
            <ProductLinkCellLazyMinimal
              spaceId={o.spaceId}
              productId={row.group.link_id}
            />
          );
        },
        align: 'left',
        sortable: false,
        width: 200,
        flexGrow: 4
      },
      sorter: {
        key: 'link',
        items: {
          sort: (p) => p.group.link_id,
          dir: 'asc'
        }
      }
    },
    pages: {
      column: {
        key: 'pages',
        head: () => 'Page URL(s)',
        cell: (row) => {
          return row.data.agg_uniq_page_url?.curr?.join(', ') ?? '';
        },
        align: 'left',
        sortable: false,
        width: 200,
        flexGrow: 4
      },
      sorter: {
        key: 'pages',
        items: {
          sort: (p) => p.data.agg_uniq_page_url?.curr[0] ?? '',
          dir: 'asc'
        }
      }
    }
  };
};

const toOnsiteLink = (
  p: AnalyticsResponseRowWithComparison
): CampaignOnsiteLink => ({
  id: p.group.link_id,
  pageUrls: p.data.agg_uniq_page_url?.curr ?? [],
  type: 'onsite'
});

const OnsiteLinksTable: React.FC<{
  campaign: IManualCampaign;
  links: Array<CampaignOnsiteLink>;
  setLinks: React.Dispatch<React.SetStateAction<Array<CampaignOnsiteLink>>>;
}> = ({ campaign, links, setLinks }) => {
  const { space } = useCurrentUser();

  const { range } = useTimeframe();

  const query = useMemo<AnalyticsQuery>(() => {
    return {
      range,
      select: ['commission_sum_net', 'agg_uniq_page_url'],
      groupBy: ['link_id'],
      filters: [
        {
          field: 'page_url',
          condition: 'in',
          values: campaign.pageUrls.map(removeTrailingSlash)
        }
      ],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }]
    };
  }, [campaign.pageUrls, range]);

  const [data, loading] = useAnalyticsQueryV2(space.id, query);

  const toggleLink = useCallback(
    (link: CampaignOnsiteLink) => {
      setLinks((links) => {
        const linkIds = links.map((link) => link.id);
        if (linkIds.includes(link.id)) {
          return links.filter((l) => l.id !== link.id);
        } else {
          return [...links, link];
        }
      });
    },
    [setLinks]
  );

  const toggleAll = useCallback(() => {
    const allLinks = data ? data.rows.map(toOnsiteLink) : [];
    setLinks((links) => {
      if (links.length === allLinks.length) {
        return [];
      } else {
        return allLinks;
      }
    });
  }, [data, setLinks]);

  const isAllSelected = useMemo(
    () => links.length === ((data && data.rows.length) ?? 0),
    [data, links.length]
  );

  const linkIds = useMemo(() => new Set(links.map((link) => link.id)), [links]);

  const columnDefinitions = useMemo(
    () =>
      getOnsiteColumnDefinitions({
        linkIds,
        toggleLink,
        toggleAll,
        isAllSelected
      }),
    [isAllSelected, linkIds, toggleAll, toggleLink]
  );

  const { tableProps } = useAnalyticsTable(
    onsiteLinkTableColumns,
    columnDefinitions,
    {
      pageSize: 1000,
      defaultSortColumn: 'commission_sum_net',
      defaultVisibleColumns: onsiteLinkTableColumns
    }
  );

  if (!data || loading) {
    return (
      <Centered height={LINK_TABLE_HEIGHT} style={{ width: '100%' }}>
        <Loader size={36} />
      </Centered>
    );
  }

  if (data.rows.length === 0) {
    return (
      <LinkTableWrapper>
        <Centered height={LINK_TABLE_HEIGHT - 5} style={{ width: '100%' }}>
          <Typography
            variant="body1"
            style={{ textAlign: 'center', maxWidth: '60%', margin: '0 auto' }}
            color="textSecondary"
          >
            <strong>No onsite links available.</strong>
            <br />
            Add new onsite links to this campaign once they've received at least
            one impression during the campaign timeframe.
          </Typography>
        </Centered>
      </LinkTableWrapper>
    );
  }

  return (
    <LinkTableWrapper>
      <RowsRenderer
        {...tableProps}
        renderHead
        variant="contained"
        rows={data.rows}
        rowToKey={(row) => row.group.link_id}
      />
    </LinkTableWrapper>
  );
};

const OnsiteLinksSummary = ({
  links
}: {
  links: Array<CampaignOnsiteLink>;
}) => (
  <AlertBox variant="pending" style={{ width: '100%' }}>
    <Typography variant="body2" component="p">
      <strong>{pluralize('link', links.length, true)}</strong> selected across{' '}
      <strong>
        {pluralize('page', uniq(links.flatMap((l) => l.pageUrls)).length, true)}
      </strong>
    </Typography>
  </AlertBox>
);

const OnsiteInventory = ({
  campaign,
  links,
  setLinks
}: {
  links: Array<CampaignOnsiteLink>;
  setLinks: React.Dispatch<React.SetStateAction<Array<CampaignOnsiteLink>>>;
  campaign: IManualCampaign;
}) => {
  return (
    <div>
      <Typography variant="body2" component="p">
        <strong>How it works:</strong> Select link placements from existing
        analytics data. If your campaign content isn't fully deployed, you'll be
        able to select more links once they've received at least one impression
        during the campaign timeframe.
      </Typography>
      <OnsiteLinksTable campaign={campaign} links={links} setLinks={setLinks} />
      <OnsiteLinksSummary links={links} />
    </div>
  );
};

const offsiteLinksColumns = ['link', 'channels', 'selector'] as const;
type OffsiteCustomColumns = typeof offsiteLinksColumns[number];
type OffsiteColumn = OffsiteCustomColumns | Metric;

const offsiteLinkTableColumns: OffsiteColumn[] = [
  'selector',
  'link',
  'channels',
  'commission_sum_net'
];

const getOffsiteColumnDefinitions = ({
  linkIds,
  toggleLink,
  toggleAll,
  isAllSelected
}: {
  linkIds: Set<string>;
  toggleLink: (link: CampaignOffsiteLink) => void;
  isAllSelected: boolean;
  toggleAll: () => void;
}): AnalyticsColumnDefinitions<OffsiteCustomColumns> => {
  return {
    selector: {
      column: {
        key: 'selector',
        head: () => (
          <Tooltip title={`Select all links`} placement="top">
            <SelectionBox value={isAllSelected} onChange={toggleAll} />
          </Tooltip>
        ),
        cell: (p) => (
          <SelectionBox
            value={linkIds.has(p.group.link_id)}
            onChange={() => toggleLink(toOffsiteLink(p))}
          />
        ),
        align: 'center',
        sortable: false,
        width: 50,
        flexGrow: 0
      },
      sorter: {
        key: 'selector',
        items: {
          sort: (p) => p.group.link_id,
          dir: 'asc'
        }
      }
    },
    link: {
      column: {
        key: 'link',
        head: () => 'Link name or deep link',
        cell: (row, o) => {
          return (
            <ProductLinkCellLazyMinimal
              spaceId={o.spaceId}
              productId={row.group.link_id}
            />
          );
        },
        align: 'left',
        sortable: false,
        width: 200,
        flexGrow: 4
      },
      sorter: {
        key: 'link',
        items: {
          sort: (p) => p.group.link_id,
          dir: 'asc'
        }
      }
    },
    channels: {
      column: {
        key: 'channels',
        head: () => 'Channel(s)',
        cell: (row) => {
          return (
            <FlexContainer wrap="wrap">
              {row.data.agg_uniq_channel_id?.curr?.map((channelId) => (
                <ChannelIdLabel key={channelId} channelId={channelId} />
              ))}
            </FlexContainer>
          );
        },
        align: 'left',
        sortable: false,
        width: 200,
        flexGrow: 4
      },
      sorter: {
        key: 'channels',
        items: {
          sort: (p) => p.data.agg_uniq_channel_id?.curr[0] ?? '',
          dir: 'asc'
        }
      }
    }
  };
};

const toOffsiteLink = (
  p: AnalyticsResponseRowWithComparison
): CampaignOffsiteLink => ({
  id: p.group.link_id,
  channels: p.data.agg_uniq_channel_id?.curr ?? [],
  type: 'offsite'
});

const OffsiteLinksTable: React.FC<{
  links: Array<CampaignOffsiteLink>;
  setLinks: React.Dispatch<React.SetStateAction<Array<CampaignOffsiteLink>>>;
}> = ({ links, setLinks }) => {
  const { space } = useCurrentUser();
  const { range } = useTimeframe();

  const [data, loading] = usePromise(async () => {
    const channels = await store()
      .collection(FS.channels)
      .where('spaceId', '==', space.id)
      .get()
      .then((snapshot) => snapshot.docs.map(toChannelDoc).map((d) => d.data));

    const query: AnalyticsQuery = {
      range,
      select: ['commission_sum_net', 'agg_uniq_channel_id'],
      groupBy: ['link_id'],
      filters: [
        {
          field: 'channel_id',
          condition: 'in',
          values: channels.map((c) => c.channelId)
        },
        {
          field: 'link_id',
          condition: 'not in',
          values: ['']
        }
      ],
      orderBy: [{ field: 'commission_sum_net', direction: 'DESC' }]
    };

    return pub_queryAnalyticsV2(space.id, query);
  }, [space.id, range]);

  const toggleLink = useCallback(
    (link: CampaignOffsiteLink) => {
      setLinks((links) => {
        const linkIds = links.map((link) => link.id);
        if (linkIds.includes(link.id)) {
          return links.filter((l) => l.id !== link.id);
        } else {
          return [...links, link];
        }
      });
    },
    [setLinks]
  );

  const toggleAll = useCallback(() => {
    const allLinks = data ? data.rows.map(toOffsiteLink) : [];
    setLinks((links) => {
      if (links.length === allLinks.length) {
        return [];
      } else {
        return allLinks;
      }
    });
  }, [data, setLinks]);

  const isAllSelected = useMemo(
    () => links.length === ((data && data.rows.length) ?? 0),
    [data, links.length]
  );

  const linkIds = useMemo(() => new Set(links.map((link) => link.id)), [links]);

  const columnDefinitions = useMemo(
    () =>
      getOffsiteColumnDefinitions({
        linkIds,
        toggleLink,
        toggleAll,
        isAllSelected
      }),
    [isAllSelected, linkIds, toggleAll, toggleLink]
  );

  const { tableProps } = useAnalyticsTable(
    offsiteLinkTableColumns,
    columnDefinitions,
    {
      pageSize: 1000,
      defaultSortColumn: 'commission_sum_net',
      defaultVisibleColumns: offsiteLinkTableColumns
    }
  );

  if (!data || loading) {
    return (
      <Centered height={LINK_TABLE_HEIGHT} style={{ width: '100%' }}>
        <Loader size={36} />
      </Centered>
    );
  }

  if (data.rows.length === 0) {
    return (
      <LinkTableWrapper>
        <Centered height={LINK_TABLE_HEIGHT - 5} style={{ width: '100%' }}>
          <Typography
            variant="body1"
            style={{ textAlign: 'center', maxWidth: '60%', margin: '0 auto' }}
            color="textSecondary"
          >
            <strong>No offsite links available.</strong>
            <br />
            Add new offsite links to this campaign once they've received at
            least one click during the campaign timeframe.
          </Typography>
        </Centered>
      </LinkTableWrapper>
    );
  }

  return (
    <LinkTableWrapper>
      <RowsRenderer
        {...tableProps}
        renderHead
        variant="contained"
        rows={data.rows}
        rowToKey={(row) => row.group.link_id}
      />
    </LinkTableWrapper>
  );
};

const OffsiteLinksSummary = ({
  links
}: {
  links: Array<CampaignOffsiteLink>;
}) => (
  <AlertBox variant="pending">
    <Typography variant="body2" component="p">
      <strong>{pluralize('placement', links.length, true)}</strong> selected
      across{' '}
      <strong>
        {pluralize(
          'channel',
          uniq(links.flatMap((l) => l.channels)).length,
          true
        )}
      </strong>
    </Typography>
  </AlertBox>
);

interface OffsiteInventoryProps {
  setLinks: React.Dispatch<React.SetStateAction<Array<CampaignOffsiteLink>>>;
  links: CampaignOffsiteLink[];
}

const OffsiteInventory: React.FC<OffsiteInventoryProps> = ({
  links,
  setLinks
}) => (
  <div>
    <Typography variant="body2" component="p">
      <strong>How it works:</strong> Select link placements from existing
      analytics data. Offsite links will appear below once they've received at
      least one click during the campaign timeframe.
    </Typography>
    <OffsiteLinksTable links={links} setLinks={setLinks} />
    <OffsiteLinksSummary links={links} />
  </div>
);

export const CampaignLinksForm: React.FC<CampaignLinksFormProps> = ({
  campaign,
  proceed,
  back
}) => {
  const [tab, setTab] = useNumberQueryParam('tab', 0);
  const [onsiteLinks, setOnsiteLinks] = useState(
    campaign.links.filter(isOnsiteLink)
  );

  const [offsiteLinks, setOffsiteLinks] = useState(
    campaign.links.filter(isOffsiteLink)
  );

  return (
    <Card>
      <Tabs value={tab} onChange={(_, value) => setTab(value)}>
        <Tab color="inherit" label="ONSITE INVENTORY" key={0} />
        <Tab color="inherit" label="OFFSITE INVENTORY" key={1} />
        <Tab color="inherit" label="CREATE NEW INVENTORY" key={2} />
      </Tabs>
      <CardContent>
        {tab === 0 && (
          <OnsiteInventory
            campaign={campaign}
            links={onsiteLinks}
            setLinks={setOnsiteLinks}
          />
        )}
        {tab === 1 && (
          <OffsiteInventory links={offsiteLinks} setLinks={setOffsiteLinks} />
        )}
        {tab === 2 && (
          <Centered>
            <Header>Coming soon!</Header>
          </Centered>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            proceed({ links: [...onsiteLinks, ...offsiteLinks] }, campaign)
          }
        >
          Save and continue
        </Button>
        <Button color="default" onClick={back}>
          Back to content
        </Button>
      </CardActions>
    </Card>
  );
};
