import { compact, isNil } from 'lodash';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import {
  AnalyticsQuery,
  AnalyticsResponse
} from '../../../../domainTypes/analytics_v2';
import { isOffsiteLink } from '../../../../domainTypes/campaigns';
import { usePromise } from '../../../../hooks/usePromise';
import { pub_queryAnalyticsV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser } from '../../../../services/currentUser';
import { LoadingValue, useMappedLoadingValue } from '../../../../services/db';
import { removeTrailingSlash } from '../../../../services/url';
import {
  calculateEarningsGoal,
  getClicksGoal,
  getPageviewsGoal
} from '../../service/goals-and-incentives';
import {
  CompletedManualCampaign,
  isCampaignAtLeastScheduled,
  ManualCampaign,
  RunningManualCampaign,
  ScheduledManualCampaign
} from '../../service/manual-campaign';

export interface CampaignGoal {
  value: number;
  percentage: number;
  status: 'onTrack' | 'behind' | 'pending';
}

export interface CampaignTotals {
  clicks: number;
  pageviews: number;
  impressions: number;
  ctr: number;
  gmv: number;
  aov: number;
  orders: number;
  conversionRate: number;
}

const getQuery = (
  campaign:
    | ScheduledManualCampaign
    | RunningManualCampaign
    | CompletedManualCampaign
): AnalyticsQuery => {
  return {
    select: ['c', 'p', 'v', 'ctr', 'gmv_sum_net', 'aov_net', 'order_count_net'],
    range: {
      start: campaign.timeframe.start.toISOString(),
      end: campaign.timeframe.end.toISOString()
    },
    filters: compact([
      campaign.links.length > 0 && {
        field: 'link_id',
        condition: 'in',
        values: campaign.links.map((l) => l.id)
      },
      campaign.pageUrls.length > 0 && {
        field: 'page_url',
        condition: 'in',
        values: [
          ...campaign.pageUrls.map(removeTrailingSlash),
          // can't use lodash.compact, because "''" is falsy
          ...(campaign.links.some(isOffsiteLink) ? [''] : [])
        ]
      }
    ])
  };
};

function readGoalStatus(campaign: ManualCampaign, goalRatio: number) {
  if (campaign.status === 'planning') {
    return 'pending';
  }
  const durationRatio =
    moment().diff(campaign.timeframe.start, 's') /
    moment(campaign.timeframe.end).diff(campaign.timeframe.start, 's');
  return goalRatio >= durationRatio ? 'onTrack' : 'behind';
}

const readGoal = (
  campaign: ManualCampaign,
  goal: number | null,
  total: number
): CampaignGoal | undefined => {
  if (isNil(goal)) return undefined;
  const percentage = total / goal;
  const status = readGoalStatus(campaign, percentage);
  return {
    value: goal,
    percentage: percentage,
    status
  };
};

const readCampaignGoals = (
  campaign: ManualCampaign,
  totals: { gmv: number; clicks: number; pageviews: number }
): CampaignData['goals'] => {
  return {
    gmv: readGoal(campaign, calculateEarningsGoal(campaign), totals.gmv),
    clicks: readGoal(campaign, getClicksGoal(campaign), totals.clicks),
    pageviews: readGoal(campaign, getPageviewsGoal(campaign), totals.pageviews)
  };
};

const readCampaignTotals = (
  campaign: ManualCampaign,
  response: AnalyticsResponse
): CampaignTotals & { id: string } => {
  const data = response.rows[0].data;

  const clicks = data.c?.curr ?? 0;
  const orders = data.order_count_net?.curr ?? 0;

  return {
    id: campaign.id,
    clicks,
    pageviews: data.p?.curr ?? 0,
    impressions: data.v?.curr ?? 0,
    ctr: data.ctr?.curr ?? 0,
    gmv: data.gmv_sum_net?.curr ?? 0,
    aov: data.aov_net?.curr ?? 0,
    orders,
    conversionRate: orders / clicks
  };
};

export type CampaignData = {
  campaign: ManualCampaign;
  totals?: CampaignTotals;
  goals: {
    gmv?: CampaignGoal;
    clicks?: CampaignGoal;
    pageviews?: CampaignGoal;
  };
};

export const useCampaignsData = (
  campaigns: ManualCampaign[]
): LoadingValue<Array<CampaignData>> => {
  const { space } = useCurrentUser();
  const tasks = useMemo<Array<[ManualCampaign, AnalyticsQuery]>>(
    () =>
      campaigns.filter(isCampaignAtLeastScheduled).map((c) => [c, getQuery(c)]),
    [campaigns]
  );

  const data = usePromise(() => {
    return Promise.all(
      tasks.map(([campaign, query]) =>
        pub_queryAnalyticsV2(space.id, query).then((response) =>
          readCampaignTotals(campaign, response)
        )
      )
    );
  }, [tasks]);

  return useMappedLoadingValue(data, (data) => {
    return campaigns.map((campaign: ManualCampaign) => {
      const totals: CampaignTotals | undefined = data.find(
        (d) => d.id === campaign.id
      );
      const goals = readCampaignGoals(campaign, {
        gmv: totals?.gmv ?? 0,
        clicks: totals?.clicks ?? 0,
        pageviews: totals?.pageviews ?? 0
      });
      return {
        campaign,
        totals: totals,
        goals: goals
      };
    });
  });
};
