import { useCallback, useMemo } from 'react';
import { CachedAdvertiser } from '../../../../domainTypes/advertiser';
import { Doc } from '../../../../domainTypes/document';
import { pub_queryAnalyticsV2 } from '../../../../services/analyticsV2/query';
import { useCurrentUser as _useCurrentUser } from '../../../../services/currentUser';
import { useCustomDimensionsOptions } from '../../../../services/customDimensions';
import { useFeatureEnabled } from '../../../../services/features';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { CF } from '../../../../versions';
import {
  AnalyticsQuery,
  ICreateLinkOptions,
  LinkGeneratorOption,
  LinkGeneratorOptionGroup,
  LinkGeneratorRequest,
  LinkGeneratorResponse
} from './types';

export { Currency, Number } from '../../../../components/Number';
export { PARTNERS } from '../../../../domainTypes/partners';
export { styled } from '../../../../emotion';
export { withStoppedPropagation } from '../../../../helpers';
export { useChannels } from '../../../../services/channels';
export { getStableRandomColor } from '../../../../services/color';
export { refreshTimestamp } from '../../../../services/db';
export { useFeatureEnabled } from '../../../../services/features';
export { useLinkShortener } from '../../../../services/linkShortener';
export { useLocalJsonStorage } from '../../../../services/localJsonStorage';
export { cookieDurationString } from '../../../PerformanceNew/components/RatesTable/RatesDrawer';

export const useCurrentUser = _useCurrentUser;

const createLink = (
  url: string,
  subid: string,
  spaceId: string,
  options?: ICreateLinkOptions
) => {
  const request: LinkGeneratorRequest = {
    url,
    subid,
    spaceId,
    options,
    source: 'app'
  };
  return callFirebaseFunction<LinkGeneratorResponse>(
    CF.tools.createLink,
    request
  );
};

export const useLinkCreator = () => {
  return useCallback(
    (
      url: string,
      subid: string,
      spaceId: string,
      options?: ICreateLinkOptions
    ) => createLink(url, subid, spaceId, options),
    []
  );
};

const checkRates = async (url: string, spaceId: string) => {
  const advertisers = await callFirebaseFunction<Doc<CachedAdvertiser>[]>(
    CF.tools.getAdvertisersByOriginDomain,
    { url, spaceId }
  );
  return advertisers;
};

export const useCheckRates = () => {
  const { space } = useCurrentUser();
  return useCallback(
    async (url: string) => {
      return checkRates(url, space.id);
    },
    [space.id]
  );
};

export const useCustomDimensionGeneratorOptions = () => {
  const hasLinkWrapper = useFeatureEnabled('LINK_WRAPPER');
  const [customDimensions = []] = useCustomDimensionsOptions();

  return useMemo<LinkGeneratorOption[]>(() => {
    if (!hasLinkWrapper) return [];
    return customDimensions.map(
      ({ linkGeneratorSettings, name, options, slot }) => {
        const baseSettings = {
          value: slot,
          label: name,
          group: LinkGeneratorOptionGroup.Offsite
        };

        if (linkGeneratorSettings.type === 'dictionary') {
          return {
            ...baseSettings,
            inputType: 'select',
            options: options.map((option) => {
              return {
                value: option.id,
                label: option.name
              };
            })
          };
        }
        return {
          ...baseSettings,
          inputType: 'text'
        };
      }
    );
  }, [customDimensions, hasLinkWrapper]);
};

export const useSendAnalyticsQuery = () => {
  const { space } = _useCurrentUser();
  return useCallback(
    async (query: AnalyticsQuery) => {
      return pub_queryAnalyticsV2(space.id, query);
    },
    [space.id]
  );
};
